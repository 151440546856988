<template>
    <div class="Find">

        <div class="discovery">
            <img src="https://up.fumamx.com/v2/image/1,05ac136bf95c/@100" alt="1,05ac136bf95c" />
            <div>找买家功能正在紧张开发中</div>
            <span>敬请期待…</span>
            <!--  <div v-if="Global.config.STAGE_ENV=='dev'" @click="openGo()">打开新页面</div> -->
        </div>
        <!-- 找买家<br>
        找买家<br>
        找买家<br><br><br>
        <van-checkbox v-model="checked">复选框</van-checkbox>

        <div class="title">SVG:</div>
        <svg class="iconSVG" aria-hidden="true" style="font-size:50px;">
          <use xlink:href="#file-docx"></use>
        </svg>
        <svg class="iconSVG" aria-hidden="true" style="font-size:50px;">
          <use xlink:href="#file-pdf"></use>
        </svg>

        <div class="title">暂无数据：</div>

        <div class="title">自定义参数：</div>
        title="暂无邮件"<br> img="noMail" (noAddress, noCustomer, noSearch...)
        <nodata></nodata>
        <nodata></nodata>
        <nodata></nodata>
        <nodata></nodata>
        <nodata></nodata>
        <nodata title="暂无邮件" img="noMail"></nodata>

        <div class="title">loading:</div>
        自定义参数：size="30px"
        <loading></loading>

        <hr>
        <van-button type="primary">主要按钮</van-button>
        <van-button size="large">大号按钮</van-button>
        <hr>
      -->
        <!-- <div class="van-hairline--top"></div> -->

        <!-- 下边框 -->
        <!-- <div class="van-hairline--bottom"></div> -->

    </div>
</template>

<script>
import titleMixin from '../../mixin/title'
// import BScroll from 'better-scroll'

export default {
    name: 'Find',
    mixins: [titleMixin],
    title: '找买家',
    data() {
        return {
            // checked: true,
            // scroll: null
        }
    },
    mounted() {
        this.quickAdd() // 全局右上角快捷新增
        // setTimeout(() => {
        //   let config = {
        //     scrollY: true,
        //     click: true
        //   }
        //   this.scroll = new BScroll(this.$el, config)
        // }, 20)
    },
    methods: {
        openGo() {
            window.open(`${location.protocol}//${location.host}/pages/editMail`)
        }
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else {
            next()
        }
    },
    watch: {
        $route() {
            let path = this.$route.path
            if (path === '/find' || path === '/find/') {
                this.quickAdd() // 全局右上角快捷新增
            }
        }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.Find {
    // border:1px solid red;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    .discovery {
        padding-top: 15%;
        text-align: center;
        color: #909399;
        > img {
            width: 60%;
            margin-bottom: 5%;
        }
        // .font-size(12px);
        > span {
            display: block;
            color: #d0021b;
            // .font-size(13px);
            padding-top: 0.12rem;
        }
    }
}
</style>
